.request-sent-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    .message-container {
        max-width: 600vw;
        padding: 2rem;
        text-align: center;
    
        .org-logo {
          margin-bottom: 1.5rem;
    
          .org-img {
            max-width: 100px;
            max-height: 100px;
            object-fit: contain;
          }
        }
      }
}