.dialog-div{
    font-size: 2rem;
    min-height: 70%;
    .title{
        font-size: 24px !important;
    }
    .submission-date{
        font-size: 14px !important;
        color: #b2aeae !important;
        margin-right: 5px;
        margin-bottom: 0.2rem;
        .requested-by{
            font-size: 18px;
            .submitted-by{
                margin-left: 5px;
            }
        }
    }
}

.action-box{
    position: absolute;
    bottom: 0px;
    background-color: #fff;
    width: calc(100% - 32px);
    text-align: end;
    padding: 16px 0px;
    button {
        margin-left: 12px;
    }
}