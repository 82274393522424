@import '../../../../assets/css/variable/style.scss';

.broadcast-analytics {
    .tags {
        .head {
            display: flex;
            align-items: center;
            padding: 10px 0px;
        }
        .tot-box-3 {
            width: 33.33%;
        }

        .tot-box-5 {
            width: 20%;
        }

        .count-box {
            padding: 15px;
            border-right: 1px solid $border-color;
            height: 90px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .icon {
                width: 45px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
            }

            .red {
                background-color: rgba(252, 64, 17, 0.2);
            }

            .blue {
                background-color: rgba(17, 140, 252, 0.2);
            }

            .green {
                background-color: #11fc2f33;
            }
        }

        .last-count-box {
            border-right: none;
        }

        .overview-tabs {
            border-radius: 10px;
            display: flex;
            border: 1px solid $border-color;
            overflow: hidden;
        }

        .cards-title {
            color: #333333;
        }

        .analytics {
            color: #333333;
            font-weight: 600;
        }
    }
}