.inline-dialog-form{
    overflow: auto;
    padding-bottom: 20px
}


.inline-dialog-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    padding-top: 10px;
    margin-top: 10px; 
}
