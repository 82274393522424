.dynamicKeyword{
    display: flex;
    margin-bottom: 10px;
    gap: 10px;
    font-family: var("--font-family");
    .select-style {
        width: 20%;
        height: 30px;
        font-size: 0.8rem;
      }
      .insert-button{
        height: 30px;
        font-size: 0.7rem;
      }
};