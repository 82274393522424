@import "../../../../assets/css/variable/style.scss";

#comments {
    border: 1px solid $border-color;
    height: calc(100vh - 160px);
    border-radius: 8px;

    header {
        background-color: #E9E9EF;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 7px 7px 0px 0px;
    }

    .note-container {
        padding: 15px;
        overflow: auto;
        height: calc(100% - 81px);

        .add-note {
            display: flex;
            align-items: center;
            background-color: #F3F4F5;
            padding: 8px 15px;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                background-color: rgba(17, 140, 252, 0.2);
            }

            .plus-icon {
                color: $primary-color;
                margin-right: 10px;
            }
        }

        .form-border {
            border-radius: 5px;
        }

        .load-more{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .activity-time{
            display: flex;
            justify-content: flex-end;
            margin-top: 5px;
        }

        .note-input {
            .title {
                input {
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    letter-spacing: 0.00938em;
                    color: #333333;
                }
            }

            .description {
                display: block;

                textarea {
                    font-weight: 400;
                    font-size: 0.75rem;
                    line-height: 1.66;
                    letter-spacing: 0.03333em;
                    color: #333333;
                }
            }

            textarea {
                resize: none;
            }

            .add-btn {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .date{
        font-size: 12px;
        color: #666;
        margin-left: 10px;
    }
}