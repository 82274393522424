@import '../assets//css//variable/style.scss';
$light-background: #ffffff;

// left navbar
.left-side-nav {
  width: 200px;
  background-color: var(--background-primary);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0rem 0.5rem;
  z-index: 1;
  box-shadow: inset -3px -2px 8px #00000029;
  transition: width .2s linear;

  .nav-items-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 115px) !important;
  }

  .nav-items-collapse-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 75px) !important;
  }

  .listItem-text {
    font-weight: 400;
    font-size: .8rem;
    color: var(--secondary-color);
  }
  .main-menu-primary {
    font-size: 14.4px !important;
    margin-left: 6px !important;
  }
  .active-listItem-text {
    font-weight: 400;
    font-size: .8rem;
    color: var(--secondary-color);
  }

  .navIcons {
    min-width: 40px;
  }

  .active-item {
    background-color: var(--background-secondary) !important;
    border-radius: 0px;
  }
  .main-menu-item {
    padding-left: 9px !important;
  }
  #list-item {
    cursor: pointer;
    height: 48px !important;
    background-color: var(--background-primary);
    &:hover {
      background-color: white;
    }
  }

  .logo-box {
    width: 120px;
    height: auto;
    min-height: 100px;
    max-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    img {
      width: 70%;
      height: 80%;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  .collapse-arrow {
    width: 37px;
    height: 37px;
    padding: 5px;
    display: flex;
    border-radius: 50%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

    .collapse-arrow-icon{
      color: var(--secondary-color);
    }


    &:hover {
      background-color: $light-background;

    }

    svg {
      padding-left: 7px;
    }
  }

  .divider-color {
    background-color: #999;
  }

  .margin-top-30 {
    margin-top: 10px;
  }

  .profile-transition{
    transition: "height .2s";
  }

  .profile {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 5px;
  }

  .active-profile {
    background-color: rgb(233, 233, 233);
  }

  .profile:hover {
    background-color: rgb(233, 233, 233);
  }

  .profile-buttons-hide {
    display: none;
  }

  .profileButtonWrap {
    display: flex;
    padding: 10px;
    cursor: pointer;
  }

  .profileButtonWrap:hover {
    background-color: rgb(233, 233, 233);
  }

  .profile-name-box{
    display: flex;
    align-items: center;
  }

  .profile-name-box .name{
    margin: 0px 20px;
    white-space: nowrap;
    font-family: var(--font-family);
  }

  .profile-avatar {
    width: 32px;
    height: 32px;
  }

  #logout-icon{
    padding-left: 18px;
  }

  .rotate-icon-down svg {
    transform: rotate(180deg);
    transition: .3s ease-in-out;
  }

  .rotate-icon-up svg {
    transform: rotate(0deg);
    transition: .3s ease-in-out;
  }
}

.right-side-content {
  transition: width .2s linear;
  margin-left: calc(200px + 16px);
  padding: 0rem 1rem;

  .top-nav h5{
    margin: 20px 0px;
    text-transform: capitalize;
    font-weight: 600;
  }

}