@import '../../../assets//css//variable/style.scss';


.css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected {
    color: rgb(0 122 255) !important;
    background-color: #118cfc24 !important;
    border-radius: 3px !important;
    border: 1px solid rgb(0, 122, 255) !important;
}
.tags {
    .tags-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        margin-bottom: 12px;
        .toggle-group{
            height: 40px !important;
        }
        .filter-box{
            .filter {
                border: 1px solid darkgray;
                color: gray;
              
                &.default {
                  &:hover {
                    background-color: #f9f9f9;
                    border-color: darkgray;
                  }
                }
              
                &.active {
                  border-color: var(--primary-color);
                  color: var(--primary-color);
              
                  &:hover {
                    background-color: #f5f9ff;
                    border-color: var(--primary-color);
                  }
                }
            }
        }
    }

    .toggle-start {
        border-start-start-radius: 10px !important;
        border-end-start-radius: 10px !important;
    }
    .toggle-end {
        border-start-end-radius: 10px !important;
        border-end-end-radius: 10px !important;
    }
    .toggle-buttons{
        width: 80px !important;
        text-transform: capitalize !important;
    }
}