.login-container {
    height: 100vh;
    font-family: var(--font-family);

    .margin-top-40 {
        margin-top: 15px;
        margin-bottom: 1rem;
    }

    .login-button{
        background-color: var(--primary-color);
    }

    .login-button:hover {
        background-color: var(--primary-color-dark);
    }

    .secondary-button{
        color: var(--primary-color);
        border-color: var(--primary-color);
    }

    .secondary-button:hover {
        background-color: var(--primary-color-light);
        border-color: var(--primary-color)
    }

    .welcome {
        color: #1C2C25 !important;
        font-family: var(--font-family);
    }

    .sub-text {
        font-size: 16px;
        color: var(--primary-color);
        font-family: var(--font-family);
    }

    .login-btn{
        background-color: var(--primary-color);
        font-family: var(--font-family);
    }

    .login-btn:hover {
        background-color: var(--primary-color-dark);
    }

    .grey-500 {
        color: #666666;
    }

    .grey-400 {
        color: rgba(0, 0, 0, 0.6);
    }

    .grey-200 {
        color: rgba(0, 0, 0, 0.12);
    }

    .height-50 {
        height: 50px;
    }

    .google {
        width: 100%;
        background-color: #F1F3F4;
        color: #666666;
        border: none;
        font-weight: 400;
        cursor: pointer;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--font-family);

        .google-image{
            position: absolute;
            left: 16px;
            height: 24px;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.12);
        }

        img {
            margin-right: 98px;
            margin-left: 15px;
        }
    }

    .credential-box {
        width: 414px;
    }

    @media screen and (max-width: 422px) {
        .credential-box {
            width: initial;
            margin: 40px 10px;
        }

    }

    .pointer-cursor {
        cursor: pointer;
    }
}