@import '../../../assets/css/variable/style.scss';

#filters-container {
    width: 550px;
    height: 450px;
    padding: 0px 10px;
    overflow: auto;
    position: relative;

    .active-filter{
        color: $grey;
    }

    .filter-box {
        height: calc(100% - 108px);

        #left {
            margin-right: 10px;
            overflow: auto;
            height: 100%;
        }

        #right {
            margin-left: 34px;
            overflow: auto;
            height: 100%;
            padding: 0px 10px;

            .label-box{
                display: flex;
                justify-content: space-between;
            }
        }
    }


    .actions-btn{
        position: sticky;
        bottom: 0;
        background: #fff;
        z-index: 1;
    }

}
.css-6hp17o-MuiList-root-MuiMenu-list{
    background: #fff;
}