.alternative-content {

    .content-box{

        .drag-container{

            .drag-item{
                border: 1px solid grey;
                border-radius: 8px;

                .dropdown{
                    margin-bottom: 4px;
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    padding: 20px;

                    .dropdown-items{
                        background-color: #F7F7F7;
                        border-radius: 8px;
                        border: 1px solid #ccc;
                    }

                    .remove-section{
                        margin-top: 10px
                    }

                    .dropdown-box{
                        background-color: #F7F7F7;
                        border-radius: 8px;
                        border: 1px solid #ccc;
                    }
                }

                .checkmark {
                    margin-bottom: 4px;
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    padding: 20px;

                    .checkmark-points{
                        background-color: #F7F7F7;
                        border-radius: 8px;
                        border: 1px solid #ccc;
                    }
                }

                .table-data{
                    .table-buttons{
                        background-color: #F7F7F7;
                        border-radius: 8px;
                        border: 1px solid #ccc;
                    }
                }

                .cards{

                    .section-cards{
                        border: 1px solid #e0e0e0;
                        padding: 16px;
                        border-radius: 8px;
                        background-color: #f9f9f9;
                        margin-bottom: 10px;

                    }
                }

                .faqs{
                    .faq{
                        border: 1px solid #e0e0e0;
                        padding: 16px;
                        border-radius: 8px;
                        background-color: #f9f9f9;
                        margin-bottom: 10px;
                    }
                }
            }

            .dragging{
                border: 1px solid grey;
                border-radius: 8px;
                background-color: grey;

                .drag-handle{
                    cursor: grab;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}