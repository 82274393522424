@import "../../../../../assets//css/variable/style.scss";

.blog-content {
    height: calc(100vh - 265px);
    overflow: auto;
    padding-bottom: 50px;

    .title {
        font-weight: bold;
    }

    .timeline {
        border: 1px solid $border-color;
        height: calc(100vh - 233px);
        border-radius: 8px;

        header {
            background-color: #E9E9EF;
            padding: 5px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 7px 7px 0px 0px;
        }

        .note-container {
            padding: 2px 15px;
            overflow: auto;
            height: calc(100% - 81px);

            
        }
    }
}