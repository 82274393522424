.broadcast-view-lead-dialog {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 20px;

    .broadcast-view-lead-dialog-list {
        margin-top: -10px;

        .broadcast-view-lead-dialog-list-text {
            margin-left: 12px;
        }
    }
}
