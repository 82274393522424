@import "../../../../assets//css/variable/style.scss";


.add-mores {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end !important;
    margin-right: 0px !important;
}

.add-btn-margin {
    margin-right: 200px !important;
}