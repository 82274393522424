@import '../../../assets//css//variable/style.scss';

#email-suggestion-box {
    position: absolute;
    top: 50px;
    background-color: $white;
    width: calc(100% - 25px);
    max-height: 200px;
    left: 1px;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    z-index: 8;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow: auto;
}