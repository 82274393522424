.resource-request-form {
  margin: 0 auto;
  padding: 5px;
  .form-header {
    align-items: center;
    span {
      margin-right: 5px;
    }
  }

  .form-fields {
    margin-top: 20px;
  }

  .styled-chip{
    padding: 5px;
    margin-top: 10px;
    margin-left:5px;
  }

  .form-fields-select{
    width: 100%;
  }

  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .skills-chips {
    display: flex;
    gap: 5px;
  }
}
.dialog-footer{

  .form-actions-start {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  .action-box{
    position: absolute;
    bottom: 0px;
    background-color: #fff;
    width: calc(100% - 32px);
    text-align: end;
    padding: 16px 0px;
    z-index: 2;
  }
}