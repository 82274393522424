.table-self-feedback-container {
    .sticky-cell {
        position: sticky;
        left: 0;
        z-index: 2 !important;
        background-color: #fff;
      }
    border: 1px solid #e3e3e3;
    border-radius: 2px;
    height: calc(100vh - 200px);
    margin-bottom: 5px;

    .header{
        background-color: var(--background-primary);
        font-weight: 600;
        z-index: 1;
    }

    .fix-width{
        max-width: 180px;
        min-width: 180px;
    }

    .border{
        border-right: 2px solid #e0e0e0;
    }

    .table-body, .bg-white{
        background-color: white;
    }
}