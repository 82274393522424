
.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
    padding: 15px;
    overflow: auto;
    border-color: #f7f7f787;
    background-color: #f7f7f787!important;
    box-shadow: "rgba(0; 0; 0; 0.12) 0px 1px 3px; rgba(0; 0; 0; 0.24) 0px 1px 2px";
}

.subtitle {
    color: #74788d;
    font-weight: 500;
    font-size: 14px;
}