@import "../../../../assets//css/variable/style.scss";


.add-mores {
    display: flex;
    flex-direction: row ;
    align-items: flex-start;
    margin-left: 30px;
    margin-right: 120px;
}

.add-btn-margin {
    margin-right: 200px !important;
}