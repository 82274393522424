.data-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  flex: 1;
  row-gap: 30px;

  .cardContainer1 {
    .cardContainer2 {
      height: 90%;
      min-width: 315px;
      max-width: 315px;

      .fieldset {
        height: 100%;
      }
    }
  }
}

.gridContainer {
  margin-top: 25px;
  padding-left: 2rem;
  padding-right: 2rem;

  .categoriesContainer{
    .categories{
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }
  
  .gridItem {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    max-width: 315px;
    min-width: 315px;

    .cardContentFullHeight {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 120px;
      max-height: 120px;

      .cardFooter {
        display: flex;
        margin-top: 2rem;
        justify-content: space-between;
      }
    }
  }
}

.fieldset {
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  margin: 0;
  padding-bottom: 5px;

  .legend {
    padding: 0 8px;
    font-size: 0.875rem;
  }
}

.cheatingInfo {
  display: flex;
  justify-content: space-between;

  .cheatingInfoItem {
    margin-left: 95px;
  }

  .blackText {
    color: black;
    font-size: 14px;
  }
}

.cardContainer {
  display: flex;
  flex-direction: column;
  gap: 9px;
  .cardContentHeading{
    font-size: 28px;
  }

  .cardContentFirst {
    margin-top: -23px;
  }

  .cardContentMarks {
    display: flex;
    justify-content: space-between;
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .blackText {
      color: black;
    }
  }
}

@media (max-width: 1430px) {

  .data-container {
    .cardContainer1 {
      .cardContainer2 {
        min-width: 285px;
        max-width: 285px;
      }
    }
  }

  .cardContent .MuiTypography-root {
    font-size: 0.8rem;
  }

  .cheatingInfo .MuiTypography-root {
    font-size: 0.8rem;
  }

  .cardContentFirst span.blackText,
  .cheatingInfoItem {
    font-size: 0.8rem;
  }
}

@media (max-width: 1320px) {

  .cardContainer .cardContentMarks .MuiTypography-root {
    font-size: 0.8rem;
  }

  .cardContainer .MuiTypography-root {
    font-size: 1.2rem;
  }

  .cheatingInfo .MuiTypography-root {
    font-size: 0.8rem;
  }

  .cardContent span.blackText,
  .cheatingInfoItem {
    font-size: 0.8rem;
  }

  .data-container {
    .cardContainer1 {
      .cardContainer2 {
        min-width: 265px;
        max-width: 265px;
      }
    }
  }

  .cardContainer {
    .cardContentMarks {
      display: flex;
      gap: 40px;
    }
  }
}

@media (max-width: 1225px) {

  .data-container {
    .cardContainer1 {
      .cardContainer2 {
        min-width: 258px;
        max-width: 258px;
      }
    }
  }
}