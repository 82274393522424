.org-chart-container{
    height: calc(100vh - 170px);
    overflow: auto;
    padding: 20px;

    .org-chart-buttons{
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 20px;
        top: 70px;
        border: 1px solid #bdbdbd;
        background-color: white;
        z-index: 9;

        .zoom-button{
            border: none;
            background-color: white;
            font-size: 30px;
            color: #bdbdbd;
        }
    }

    .org-chart-div{
        margin-top: 20px;
    }
}

.node-template-div{
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    gap: 20px;
    font-size: 12px;
    min-height: 80px;

    .node-template-info{
        display: flex;
        flex-direction: column;
        text-align: left;
        line-height: 20px;

        .font-black{
            color: black;
        }
    }
}