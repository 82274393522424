.download-btn {
    position: fixed;
    top: 0;
    right: 4%;
    margin-top: 25px;
    padding: 10px;
    color: rgb(75, 75, 75);
    z-index: 1000;
}

.download-btn:hover {
    color: white;
}