@import '../../../assets/css/variable/style.scss';

#customized-dialog-title {
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
}

#dialog-form {
    overflow: auto;
    margin-bottom: 55px;
}

#dialog-action {
    position: absolute;
    bottom: 0;
    width: calc(100% - 16px);
    z-index: 1;
    border-top: 1px solid $border-color;
    padding: 13px 0px;
}

.search-boxx {
    border: 1px solid #CCCCCC;
    white-space: nowrap;
    border-radius: 5px;
    background-color: var(--background-primary);
    display: flex;
    align-items: center;
    width: 350px;
    height: calc(100% - 2px) !important;
    margin-right: 30px;

    .search-input {
        font-family: var(--font-family);
        background: transparent;
        border: navajowhite;
        width: calc(100% - 30px);
        font-size: 0.9rem;

        &:focus {
            outline: none;
        }
    }

    .search-icon {
        padding: 3px 4px;
        width: 40px !important;
        height: 30px !important;
        color: gray;
    }
}

.export-dialog {
    height: 90vh !important;
}