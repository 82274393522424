@import '../../assets//css//variable/style.scss';

#search-result {
    position: absolute;
    top: 60px;
    background-color: $white;
    width: 379px;
    height: 300px;
    right: 72px;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    z-index: 3;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}