.assessment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .search-box {
        border: 1px solid #CCCCCC;
        white-space: nowrap;
        border-radius: 5px;
        background-color: #F5F5F5;
        display: flex;
        align-items: center;
        width: 350px;
    
        .search-input {
            background: transparent;
            border: navajowhite;
            width: calc(100% - 30px);
            font-size: 0.9rem;
    
            &:focus {
                outline: none;
            }
        }
    
        .search-icon {
            padding: 3px 4px;
            width: 40px !important;
            height: 30px !important;
            color: gray;
        }
    }
}
