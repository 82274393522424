@import '../../assets//css//variable/style.scss';

.completed-event {
    background-color: green;
    /* or any other styling you want */
}

.past-event {
    background-color: red;
    /* or any other styling you want */
}

.future-event {
    background-color: blue;
    /* or any other styling you want */
}

.custom-header {
    background-color: hsla(213, 19%, 58%, 0.149) !important;
    padding: 10px !important;
}

.event-hover {
    color: rgb(0, 0, 0) !important;
    cursor: pointer;
  }

.custom-past-day {
    background-color: #80808026 !important;
    height: 150px;
}

.custom-current-day {
    background-color: #ffffff26 !important;
    height: 150px;

}

.current-day {
    position: relative;
}

.fc-view-harness{
    height: calc(100vh - 160px) !important;
// border-radius: 10px !important;
}
.fc-scrollgrid {
    border-radius: 8px;
    overflow: hidden;
}

.fc-scrollgrid-section-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.fc-scrollgrid-section-body {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.fc-daygrid-event-harness{
    --fc-event-bg-color: none !important;
    --fc-event-border-color:none !important;
}

.fc-daygrid-body.fc-daygrid-body-unbalanced {
    width: 100% !important;    
}
.fc .fc-scrollgrid, .fc .fc-scrollgrid table {
    width: 100% !important;
}

.calender-header {
    .calender-icon {
            .arrows {
                bottom: 0px;
                background-color: #F2F2F2;
                border: 1px solid #CCCCCC;
                border-radius: 4px;
            }
        }
    .filter-box {
        .filter {
            border: 1px solid darkgray;
            color: gray;

            &.default {
                &:hover {
                    background-color: #f9f9f9;
                    border-color: darkgray;
                }
            }

            &.active {
                border-color: var(--primary-color);
                color: var(--primary-color);

                &:hover {
                    background-color: #f5f9ff;
                    border-color: var(--primary-color);
                }
            }
        }

    }
}