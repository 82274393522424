.tab-header {
    .tab-header-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 12px;
        margin-bottom: 12px;
        .toggle-group{
            height: 40px !important;
        }
        .filter-box{
            .filter {
                border: 1px solid darkgray;
                color: gray;
              
                &.default {
                  &:hover {
                    background-color: #f9f9f9;
                    border-color: darkgray;
                  }
                }
              
                &.active {
                  border-color: var(--primary-color);
                  color: var(--primary-color);
              
                  &:hover {
                    background-color: #f5f9ff;
                    border-color: var(--primary-color);
                  }
                }
            }
        }
    }
}