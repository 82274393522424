@import "../../../../../assets//css/variable/style.scss";

.blog-image{
    height: calc(100vh - 215px);
}

.add-more{
    display: flex;
    align-items: flex-start;
    margin-top: 3px !important;
    margin-left: 30px;
    margin-right: 120px;
}

.add-btn-margin {
    margin-right: 200px!important;
}

    .search-bar {
      margin-bottom: 16px !important;
    }
  
    .images-container {
      overflow-y: scroll;
      height: 400px;
      padding: 8px;
      position: relative;
      min-height: 400px;
  
      .image-wrapper {
        border: 1px solid #ddd;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.2s;
        width: 100%;
        padding-top: 90%;
        position: relative;
  
        &:hover {
          .hover-overlay {
            display: flex;
          }
        }
  
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
  
        .hover-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.4);
          display: none;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-weight: bold;
          font-size: 14px;
        }
      }
  
      .image-name {
        margin-top: 8px;
        text-align: center;
      }
    }
  
    .no-images {
      text-align: center;
      padding: 20px;
      color: #555;
      font-size: 16px;
      height: 400px;
      align-content: center;
    }
  