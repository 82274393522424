.accordion-menu {
  box-shadow: none !important;
  margin: 0 !important;
  &::before {
    display: none;
  }
  .MuiAccordionSummary-root {
    min-height: 48px;
    padding: 0 16px;
    background-color: var(--background-primary);
  }
  .accordion-summary {
    display: flex;
    align-items: center;
    padding: 0px;
    height: 48px;
    &.active {
      background-color: var(--background-secondary) !important;
    }
    &.Mui-expanded {
      z-index: 1; /* Ensure it appears above other content */
    }
    .summary-content {
      display: flex;
      align-items: center;
      flex-grow: 1;
      .nav-icon {
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          font-size: 20px;
          color: var(--secondary-color);
          &.active {
            color: #0f3b8c;
          }
        }
      }
      .menu-name {
        margin-left: 16px;
        flex-grow: 1;
        .MuiTypography-root {
          font-size: 0.9rem;
          line-height: 1.2;
          word-break: break-word;
          white-space: normal;
          color: var(--secondary-color);
          max-width: 100px;
        }
      }
    }
    .MuiAccordionSummary-expandIconWrapper {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      color: var(--secondary-color);
    }
  }
    .collapsed {
      box-shadow: inset rgba(0, 0, 0, 0.1) 0px 1px 3px,
        inset rgba(0, 0, 0, 0.08) -1px 0px 3px,
        inset rgba(0, 0, 0, 0.05) 0px -1px 3px,
        inset rgba(0, 0, 0, 0.08) 1px 0px 3px;
      transition: box-shadow 0.3s ease;
    }
    .accordion-details {
      padding: 0 !important;
      background-color: #f5f5f5;
    .menu-list {
      width: 100%;
      padding: 0 !important;
      margin: 0;
      > * {
        margin-bottom: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  // Prevent content shift when expanding/collapsing
  .MuiCollapse-root,
  .MuiCollapse-wrapper {
    transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  }

  // New styles for collapsed state
  &.collapsed {
    .accordion-summary {
      justify-content: flex-start;
      .summary-content {
        justify-content: center;
        width: 40px;
      }
      .MuiAccordionSummary-expandIconWrapper {
        position: absolute;
        right: -3px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

// Add styles for tooltip
.MuiTooltip-tooltip {
   font-size: 12px; 
   padding: 8px 12px; 
}
// Ensure the tooltip works with disabled elements
.MuiTooltip-popper { 
   pointer-events: auto !important; 
}