$margin-top: 20px;
$margin-right: 10px;

.copied-text-display {
  .container {
    max-width: sm;
    margin-top: $margin-top;
  }

  .list-item {
    display: flex;
    align-items: flex-start;
    
    .index {
      margin-right: $margin-right;
    }

    .card {
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      
      .card-content {
        padding: 16px;
        overflow: auto;
        height: 100%;
      }
    }
  }
}
