@import "../../../../../assets//css/variable/style.scss";

.blog-summary {

    .title {
        font-weight: bold;
    }
        header {
            background-color: #E9E9EF;
            padding: 5px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 7px 7px 0px 0px;
        }
    }
