#shareJob-container {
    width: 400px;
    height: 211px;
    padding: 0px 10px;
    overflow: auto;
    position: relative;
}

.box-position {
    top: 245px;
    left: 326px;
}

.input-container {
    border: 1px solid #e2e5ec;
    border-radius: 5px 0px 0px 5px;
    width: 303px;
    height: 40px;
    margin: 9px 0px 0px 4px;
    padding: 0px 7px;
}

.input-button {
    margin-top: -43px;
    padding-right: 19px;
    display: flex;
    justify-content: flex-end;
    height: 20px;

    .copy-button {
        height: 43px;
        border-radius: 0px;
    }

    .open-button {
        margin-right: -14px;
        height: 43px;
        border-radius: 0px 5px 5px 0px;
    }
    .copy-button {
        height: 43px;
    }
}

.share-button-container {
    display: flex;
    gap: 20px;
    margin-top: 19px;
    height: 40px;
    padding: 0px 5px 0px 5px;

    .facebook-button {
        background-color: #4267b2;
        width: 150px;
        padding: 10px;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
            background-color: #2f528b;
        }
    }

    .whatsapp-button {
        background-color: #4ac959;
        width: 150px;
        padding: 10px;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
            background-color: #39954f;
        }
    }

    .linkedin-button {
        background-color: #4267b2;
        width: 150px;
        padding: 10px;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
            background-color: #2f528b;
        }
    }

}