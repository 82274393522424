@import '../../../../assets//css//variable/style.scss';

.count-box {
    padding: 15px;
    width: 18.28%;
    border-right: 1px solid $border-color;
    height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.last-count-box {
    border-right: none;
}

.overview-tabs {
    border-radius: 10px;
    display: flex;
    border: 1px solid $border-color;
    overflow: hidden;
}

.cmsCard{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.cards-title {
    color: #333333;
}

.analytics {
    color: #333333;
    font-weight: 600;
}