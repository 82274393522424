.ql-toolbar {
    border: none !important;
}

/* Optional: Add border to input area */
.ql-editor {
    border: 1px solid #ccc;
}

.ql-editor-error .ql-editor {
    border: 1px solid red !important;
  }

  .form-action-btn{
    margin-top: 100px;
  }