.table-container {
    border: 1px solid #e3e3e3;
    border-radius: 2px;

    .table {
        .table-head {
            background-color: --var(--background-primary) !important;
            position: relative;
            z-index: 2;
        }
    }
}
.pagination-box{
    display: flex;
    align-items: center;
    margin-top: 10px;

    .limit-box{
        display: flex;
        align-items: center;
    
        .limit-box-select{
            margin-left: 20px;
            width: 80px;
        }
    
        .limit-box-text{
            margin-left: 10px;
        }
    }
    
}

.sortable-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; 
    
    .sort-arrow {
      position: absolute;
      left: 4px;
      font-size: 12px; 
      cursor: pointer;
      color: gray; 
  
      &.active {
        color: #118cfc; 
      }
  
      &.asc {
        bottom: -5px; 
      }
  
      &.desc {
        top: -5px; 
      }
    }
  }
  
