@import "../../../../../assets//css/variable/style.scss";

.blog-content {
    height: calc(100vh - 265px);
    overflow: auto;
    padding-bottom: 50px;

    .title {
        font-weight: bold;
    }

    .full-screen-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .images-box{
            display: flex;
            gap: 10px;
        }
    }

    .timeline {
        border: 1px solid $border-color;
        height: calc(100vh - 233px);
        border-radius: 8px;

        header {
            background-color: #E9E9EF;
            padding: 5px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 7px 7px 0px 0px;
        }

        .note-container {
            overflow: auto;
            height: calc(100% - 81px);
            padding: 0 !important;
        }
        .accordian-details{
            display: flex;
            flex-direction: column;
            width:100%;
        }
        .date{
            display: flex;
            flex-direction: row;
        }
    }
}
.images-blogs-container {
    overflow-y: scroll;
    min-height: 160px;
    max-height: 500px;
    padding: 8px;
    position: relative;

    .image-blogs-wrapper {
      border: 1px solid #ddd;
      overflow: hidden;
      cursor: pointer;
      transition: transform 0.2s;
      width: 100%;
      padding-top: 90%;
      position: relative;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }


.css-la3ohk-MuiPaper-root-MuiAccordion-root::before{
    top:0px !important;
}
.css-87xvw::before {
    top: 0px !important;
}
.css-1j37bbt-MuiPaper-root-MuiAccordion-root::before {
    background-color: rgb(255 255 255 / 12%) !important;
}


.ck.ck-editor__main>.ck-editor__editable {
    min-height: 370px;
    max-height: 370px ;
}

.ck.ck-editor__main>.ck-source-editing-area  {
    min-height: 370px;
    max-height: 370px ;
}
.ck.ck-editor__main>.ck-source-editing-area>* {
    overflow: auto;
}
P {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

.fullScreenEditor{
    .ck.ck-editor__main>.ck-editor__editable {
        min-height: calc(100vh - 78px);
        max-height: calc(100vh - 78px);
    }
    
    .ck.ck-editor__main>.ck-source-editing-area  {
        min-height: calc(100vh - 78px);
        max-height: calc(100vh - 78px);
    }
    .ck.ck-editor__main>.ck-source-editing-area>* {
        overflow: auto;
    }
}
