// #custom-mobile-number {
    .custom-number {
        display: flex;
        border-radius: 3px;
        gap: 2px;
    }

    #flag-dropdown {
        min-width: 50px;
    }

    .flag-img {
        width: 22px;
        margin: 0px 5px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
// }