@import "../../../assets//css//variable/style.scss";

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    background: #fff;

    img.upload-img {
        height: 130px;
        width: 130px;
    }
}

.custom-dialog {
    width: 600px;
    position: fixed;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .link-none-underline{
        color: $primary-color;
    }
}