.ai-parser-box{
  margin-top:1rem;
  .chipclass{ 
    border-radius: 4px; 
    margin-left: 5px;
    width: 110px; 
  }
  .match-and-chip{
    margin-bottom: 30px;
  }
  .accordian-container{
    margin-top:1rem;
    margin-bottom:1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .cardContainer2{
      .fieldset{
        .summary-body{
          padding-bottom: 2rem;
        .legend{
          font-size: 16px;
        }  
       }
      }
    }
    .skill-accordian{
      box-shadow: none;
      margin-bottom: 2px;
      border: 1px solid rgb(212, 212, 212);
      .skill-acordian-detail{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
        .skill-sub-container{
          
          .skill-container{
            display: flex;
            flex-direction: row;
            align-items: center;
          }
  
        }
      }  
    }
    .accordian{
      box-shadow: none;
      margin-bottom: 2px;
      border: 1px solid rgb(212, 212, 212);

      skill-container{
        display: flex;
        skill-box{
          margin-right: 1rem;
        }       
      }
    }
  }
}
.center-message {
  display: flex;            
  justify-content: center;
  align-items: center;
  height: 50vh;
  text-align: center;
  font-size: 1.5rem; 
  color: #c8c8c8;
  gap: 1rem; 
}