@import "../../../assets/css/variable/style.scss";

.arrows {
    bottom: 0px;
    background-color: #F2F2F2;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.custom-typography {
    padding: 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    text-align: center;
    height: 25px;
    font-size: 16px;
    border: 1px solid #CCCCCC;
    background: #F2F2F2;
    font-weight: 500;
}