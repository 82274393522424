.status-chip {
    max-width: 120px;
    min-width: 120px;
    border: 1px solid;
    font-weight: bold;
    border-radius: 4px;
    padding: 8px;
    text-align: center;
    
    &--completed {
      background-color: #C8E6C9;
      border-color: #4CAF50;
      color: #4CAF50;
    }
    
    &--in-progress {
      background-color: #FFECB3;
      border-color: #FFC107;
      color: #FFC107;
    }
    
    &--not-started {
      background-color: #FFCDD2;
      border-color: #F44336;
      color: #F44336;
    }
  }
  
  .progress-container {
    display: flex;
    align-items: center;
    gap: 8px;
    min-width: 100px;
  }
  
  .progress-bar {
    width: 100px;
    height: 8px;
    border-radius: 4px;
    background-color: #E0E0E0;
    
    &__indicator {
      height: 100%;
      border-radius: 4px;
      
      &--high {
        background-color: #4CAF50;
      }
      
      &--medium {
        background-color: #FFC107;
      }
      
      &--low {
        background-color: #F44336;
      }
    }
  }