.line-chart{
    background-color: #f6f6f6;
    height: 400px;
    border-radius: 8px;
    position: relative;
    padding: 10px;

    .chart-container{
        position: absolute;
        top: 50px;
        left: 10px;
        right: 10px;
        bottom: 10px;
    }
}