@import '../../assets//css//variable/style.scss';

.dashboard {
    .tags {
        .count-box {
            padding: 15px;
            width: 14.28%;
            border-right: 1px solid $border-color;
            cursor: pointer;
            height: 185px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .last-count-box {
            border-right: none;
        }

        .overview-tabs {
            border-radius: 10px;
            display: flex;
            border: 1px solid $border-color;
            overflow: hidden;
        }

        .cards-title {
            color: #333333;
        }

        .analytics {
            color: #333333;
            font-weight: 600;
        }
    }
}

.more-details {
    font-size: 16px;
    cursor: pointer;
    font-size: 500;
}