.primaryColor {
  color: #118cfc;
  cursor: pointer;
}

.action-box{
  position: absolute;
  bottom: 0px;
  background-color: #fff;
  width: calc(100% - 32px);
  text-align: end;
  padding: 16px 0px;

  button {
      margin-left: 12px;
  }
}

.update-request-box{
  display: flex;
  justify-content: space-between;

  .update-req-inner-box{
    display: flex;
    flex-direction: column;
  }

  .user-chip{
    background-color: #45a960;
    color: white;
    height: 26px;
    margin-left: 30px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 20px;                    
  }
}

.req-skill-table{
  margin-top: 10px;
  padding-left: 30px;
  padding-right: 20px;
}