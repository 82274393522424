.composition-table {
	.composition-table-container {
		.table-container {
			.table {
				.table-head {
					.styled-table-cell {
						&.MuiTableCell-head {
							background-color: #f7f7f7;
							font-weight: 600;
							z-index: 1;
						}


						&.MuiTableCell-body {
							font-size: 14px;
						}
					}
				}
				.table-body {
					.styled-table-cell-body {
						&:nth-child(1) {
						  border-right: 1px solid #ccc; // Vertical line for the first column
						}
					
						// Default to no border-bottom for all cells
						border-bottom: 0;
					
						// Apply border-bottom for cells with the last-category-cell class
						&.last-category-cell {
						  border-bottom: 1px solid #ccc !important; // Ensure specificity
						}
					  }
						.custom-textfield{
							width: 80px;
							.centered-text{
								text-align: center;
							}
						}
				}
			}
		}
	}
}