.drawer-box {
  width: 370px;
  height: 100vh;
  background-color: white;

  .center {
      margin-bottom: 10px;
  }

  .sticky-header {
      padding: 5px 16px;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 1;
      width: 342px;
      background-color: #f8f8f8;
      height: 50px;
      justify-content: space-between;

      .mark-all-box {
        display: flex;
        align-items: center;
      }

      .mark-all-read {
          cursor: pointer;
          color: #3F98EC;
          margin-right: 8px;
          font-weight: bold;
      }

      .icon-button {
          position: sticky;
          left: 7px;
      }
  }

  .notifications{
    margin-top: 70px;
    
      .accordion-read {
          background-color: white;
          padding: 0px 10px 10px 10px;
    
          .expanded-box {
            padding: 10px;
            display: flex;
            gap: 10px;
            border: 1px solid #ccc;
            border-radius: 8px;
          }
          .timestamp {
            display: block;
            color: #545151;
        }
      }
    
      .accordion-unread {
          padding: 0px 10px 10px 10px;
    
          .expanded-box {
            background-color: #E4DEF5;
            padding: 10px;
            display: flex;
            gap: 10px;
            border: 1px solid #ccc;
            border-radius: 8px;
          }
          .timestamp {
            display: block;
            color: #545151;
        }
      }
    
      .accordion {
    
        cursor: pointer;
    
          .content {
    
              .message-preview {
                  display: block;
              }
    
              .timestamp {
                  display: block;
                  color: #545151;
              }
          }
    
          .details {
              display: block;
    
              .lead-info {
                  margin-top: 16px;
    
                  .link-none-underline {
                      text-decoration: none;
                  }
    
                  .primary-color {
                      color: #3F98EC;
                  }
    
                  .fw-bold {
                      font-weight: bold;
                  }
              }
          }
      }
  }
}
