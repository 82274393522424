#filters-container {
    width: 550px;
    height: 390px !important;
    padding: 0px 15px !important;
    overflow: auto;
    position: relative;
    background-color: #fff;

    .save-box{
        display: flex;
        justify-content: flex-end;
    }
}