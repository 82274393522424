.container {
    background-color: #f7f7f787;
    border-radius: 5px;
    padding: 10px;
}

.hideSubtitle {
    display:none
}

.subtitle {
    color: #74788d;
    font-weight: 500;
    font-size: 14px;
}

.cardCount {
    color: #313131;
    font-size: 24px;
    margin-top: 10px;
    font-weight: 500;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
    padding: 15px;
    overflow: auto;
    border-color: #f7f7f787;
    background-color: #f7f7f787!important;
    box-shadow: "rgba(0; 0; 0; 0.12) 0px 1px 3px; rgba(0; 0; 0; 0.24) 0px 1px 2px";
}
.textCenter {
    display: flex; 
    align-items: center; 
    flex-direction: column; 
    justify-content: center;
     height: 100%
}
.lightText {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: #74788d
}

.lightTextCaption {
    display: block;
    font-size: 18px;
    color: #74788d
}