.report-container {
    background-color: #f7f7f787;
    border-radius: 5;
    padding: 10px;
}

.subtitle {
    color: #74788d;
    font-weight: 500;
    font-size: 14px;
}

.cardCount {
    color: #313131;
    font-size: 24px;
    margin-top: 10px;
    font-weight: 500px;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180;
    padding: 15;
    overflow: auto;
    background-color: #f7f7f787;
    box-shadow: "rgba(0; 0; 0; 0.12) 0px 1px 3px; rgba(0; 0; 0; 0.24) 0px 1px 2px";
}

.tab {
    min-width: 0px;
    padding: 0px;
    margin-right: 15px;
    text-transform: none;
}