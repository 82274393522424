.self-evaluation {
  
  .metric-card {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    overflow: hidden;
   
    .self-evaluation-tag {
      padding: 10px;
      height: 130px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }


  .chart-container {
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding: 20px;
  }


  .filter-box {
    .filter {
      min-width: 40px;
      padding: 5px;
     
      &.active {
        background-color: #f5f5f5;
      }
     
      &.default {
        color: #666;
      }
    }
  }
}
