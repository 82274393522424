.header{
    .title-box{
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .sub-title-box{
        background-color: #f3f4f5;
        border-radius: 8px;
        padding: 5px 10px;
        font-size: 12px;
    }
}