.team-evaluation {
  margin-top: 10px;

  .team-overview-tabs {
    border-radius: 10px;
    display: flex;
    border: 1px solid #dee2e6;
    overflow: hidden;

    .team-evaluation-tag {
      padding: 15px;
      width: 33.33%;
      border-right: 1px solid #dee2e6;
      height: 130px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .analytics-message {
        color: #999999;
        font-size: 13px;
      }

      .analytics-marks{
        color: #999999;
        font-size: 20px;
        margin-top: 5px;
        margin-left: 3px;
      }
    }
  }

  .team-evaluation-overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .last-count-box {
      border-right: none;
    }

    .cards-title {
      color: #333333;
    }

    .analytics {
      color: #333333;
      font-weight: 600;
    }

    .date-range{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      background: #DAE0FD4D;
      border-radius: 25px;
      font-family: var(--font-family);
    }
  }
}