#question-manage {
    .header {
        margin: 20px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title-box {
            display: flex;
            align-items: center;

            img {
                cursor: pointer;
            }

            .title {
                font-weight: 600;
                margin-left: 10px;
            }
        }

        .save-box {
            display: flex;
            align-items: center;

            .sub-text {
                color: #333333;
            }

            .header-buttons {
                height: 40px;
                border-radius: 4px;
                box-shadow: none;
                border: none;
            }
        }
    }
}

.form-action-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}