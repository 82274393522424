#test-manage {
  .header {
      margin: 20px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title-box {
          display: flex;
          align-items: center;

          img {
              cursor: pointer;
          }

          .title {
              font-weight: 600;
              margin-left: 10px;
          }
      }

      .save-box {
          display: flex;
          align-items: center;

          .sub-text {
              color: #333333;
          }

          .header-buttons {
              height: 40px;
              border-radius: 4px;
              box-shadow: none;
              border: none;
          }

          .schedule-button {
              height: 40px;
              border-radius: 4px;
              box-shadow: none;
          }
      }
  }

  .blog-detail {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0px;

      .title {
          font-weight: 600;
      }

      .sub-text {
          color: #333333;
      }

      .note {
          display: flex;
          align-items: center;
      }
  }
}

.form-action-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}



// for moving searching button to the left 


#headerSearchLeft {
    height: 38px;
    overflow: auto;
    padding: 10px 0px;
    margin-left: auto;

    .search-box {
        border: 1px solid #CCCCCC;
        white-space: nowrap;
        border-radius: 5px;
        background-color: var(--background-primary);
        display: flex;
        align-items: center;
        width: 350px;
        height: calc(100% - 2px) !important;
        // margin-right: 10px;

        .search-input {
            font-family: var(--font-family);
            background: transparent;
            border: navajowhite;
            width: calc(100% - 30px);
            font-size: 0.9rem;

            &:focus {
                outline: none;
            }
        }

        .search-icon {
            padding: 3px 4px;
            width: 40px !important;
            height: 30px !important;
            color: gray;
        }
    }
}