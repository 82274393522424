.primaryColor {
  color: #118cfc;
  cursor: pointer;
}
.button-container{
  display: flex;
  .approve-button{
    border-radius: 4px; 
    width: 110px;
    background-color: #3DA144;
    margin-left: 0.5rem;
    margin-right: 0rem;
    height: 40px;
    color: white;
    border: 0px;
    cursor: pointer;
  }
  .rejected-button{
    border-radius: 4px; 
    font-size: 14px;
    width: 110px;
    background-color: #AA263E;
    height: 40px;
    color: white;
    border: 0px;
    cursor: pointer;
  }
  .disabled-button-approved{
    background-color: rgb(176, 172, 172);
    pointer-events: none;
    font-size: 14px;
    cursor: not-allowed;
    height: 40px;
    color: white;
    border: 0px;
    border-radius: 4px; 
    width: 110px;
    margin-left: 0.5rem;
    margin-right: 0rem;
  }
  .disabled-button-rejected{
    background-color: rgb(176, 172, 172);
    pointer-events: none;
    cursor: not-allowed;
    font-size: 14px;
    height: 40px;
    color: white;
    border: 0px;
    border-radius: 4px; 
    width: 110px;
  }
}
.status{
  width: 170px!important;
}

