.ques-divider{
    margin-top: 10px;
}
.dialogContent{
 height: 70vh;   
.ques-details{
    display: flex;
    justify-content: space-between;
}
.ques-description{
    margin: 15px 0px;
    .ques-description-heading{
        background-color: #f0f0f0;
        padding: 10px;
        border-radius: 8px;
    }
}
.ques-code{
    display: flex;
    gap: 20px;
    padding-bottom: 80px;
    .ques-editor{
        flex: 1;
        .ques-editor-div{
            overflow: hidden;
            border-radius: 8px;
        }
    }
}
.ques-output{
    flex: 1;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 8px;
    overflow-y: auto;
    height: 400px
}
}