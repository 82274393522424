    .ql-toolbar {
        border: none !important;
    }

    /* Optional: Add border to input area */
    .ql-editor {
        border: 1px solid #ccc;
    }

    .ql-editor-error .ql-editor {
        border: 1px solid red !important;
      }
.view-question {
    .weightage {
        display: flex;
        align-items: center
    }
}