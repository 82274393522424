#header {
    height: 38px;
    overflow: auto;
    padding: 10px 0px;

    .date-range{
      margin-left: 10px;
      padding: 10px 22px;
      background: #DAE0FD4D;
      border-radius: 25px;
      font-family: var(--font-family);
    }

    .search-box {
        border: 1px solid #CCCCCC;
        white-space: nowrap;
        border-radius: 5px;
        background-color: var(--background-primary);
        display: flex;
        align-items: center;
        width: 350px;
        height: calc(100% - 2px) !important;
        // margin-right: 10px;

        .search-input {
            font-family: var(--font-family);
            background: transparent;
            border: navajowhite;
            width: calc(100% - 30px);
            font-size: 0.9rem;

            &:focus {
                outline: none;
            }
        }

        .search-icon {
            padding: 3px 4px;
            width: 40px !important;
            height: 30px !important;
            color: gray;
        }
    }

    .filter-btn {
        padding: 8px 15px;
        border: 2px solid lightgray;
        color: gray;
        box-shadow: 0px 0px 0px 0px;

        &:hover {
            box-shadow: 0px 0px 0px 0px;
        }
    }

    .filter-btn-visible {
        padding: 8px 15px;
        border: 2px solid var(--primary-color);
        color: var(--primary-color);
        background-color: #118cfc33;
        box-shadow: 0px 0px 0px 0px;

        &:hover {
            background-color: #118cfc33;
            box-shadow: 0px 0px 0px 0px;
        }

    }
    .filter {
        border: 1px solid var(--primary-color);
        color: gray;
      
        &.default {
          &:hover {
            background-color: #f9f9f9;
            border-color: var(--primary-color);
          }
        }
      
        &.active {
          border-color: var(--primary-color);
          color: var(--primary-color);
      
          &:hover {
            background-color: #f5f9ff;
            border-color: var(--primary-color);
          }
        }
      }
      .import-icon{
        display: flex;
    height: 18px;
    align-items: center;
    justify-content: center;
      }

            .export-icon {
              display: flex;
              align-items: center;
              justify-content: center;
              fill: var(--primary-color);
            }
      
}