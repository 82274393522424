@import "../../../assets/css/variable/style.scss";

.automation-layout{
    margin-top: 20px;

    .module-box{
        border: 1px solid $border-color;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;

        &:hover {
            background-color: var(--background-primary);
            transition: ease-in-out .4s;
        }
    }
}