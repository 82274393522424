.search-container-cp{
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .search-box-contact-person {
        border: 1px solid #CCCCCC;
        white-space: nowrap;
        border-radius: 5px;
        background-color: var(--background-primary);
        display: flex;
        align-items: center;
        width: 450px;
        margin-bottom: 20px;
    
        .search-input {
            font-family: var(--font-family);
            background: transparent;
            border: navajowhite;
            width: calc(100% - 30px);
            font-size: 0.9rem;
    
            &:focus {
                outline: none;
            }
        }
    
        .search-icon {
            padding: 3px 4px;
            width: 40px !important;
            height: 30px !important;
            color: gray;
        }
    }
    
}
