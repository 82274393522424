.diff-added {
    background-color: #c8f7c5;
    text-decoration: underline;
}

.diff-added-image {
    border: 2px solid green;
}

.diff-removed {
    background-color: #f7c5c5;
    text-decoration: line-through;
}

.diff-removed-image {
    border: 2px solid red !important;
    opacity: 0.5 !important;
}