// InputStyles.scss

.input-field {
    background-color: #ebe9e9;
    border: none;
    height: 25px;
    outline: none;
    position: relative; 
    font-size: 0.875rem;
    font-weight: 600;
  
    .edit-icon {
      position: absolute;
      top: 2px;
      right: 2px; 
      cursor: pointer;
      color:rgb(106 103 103 / 87%); 
    }
  }
  