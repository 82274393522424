@import "./../../../../../assets/css/variable/style.scss";

.identify-box {
    width: 20px;
    height: 20px;
}

.grey-color {
    background-color: #dfe1e4;
}

.text-white {
    color: $white;
}

#timeline {
    height: calc(100% - 50px);
    overflow: scroll;
    .timelineBox {
        border-top: 1px solid #ECECEC;
        border-right: 1px solid #ECECEC;
        color: #666;
    }

    .timelineDescBox {
        border-bottom: 0 !important;
        display: flex;
    }

    .desc {
        width: calc(100% - 212px);
        ;
    }
}