@import "../../../../../assets//css/variable/style.scss";

#assignment {
    .info-box{
        padding: 15px;
        background-color: #F7F7F7;
        border-radius: 8px;
    }
    margin-bottom: 10px;

    .save-box{
        display: flex;
        justify-content: flex-end;
    }
}