.dialog-div{
    font-size: 2rem;
    min-height: 70%;
    .title{
        font-size: 24px !important;
    }
    .submission-date{
        color: #b2aeae !important;
        margin-right: 5px;
        .requested-on{
            font-size: 15px;
            .submitted-by{
                margin-left: 5px;
            }
        }
    }
    .progress{
        height: 6px !important;
    }
}